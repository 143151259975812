body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.CircularProgressbar {
  height: inherit;
}

.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}

.MuiButton-containedPrimary {
  background-color: #39b54a;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #39b54a !important;
}
.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
